// import { useNuxtApp } from 'nuxt/app'

export const useAppStore = defineStore('appStore', {
  state: () => ({
    // User
    inited: false,
    session: null,
    user: null
  }),
  actions: {
    reset() {}
  },
  getters: {}
})
